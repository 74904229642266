import Head from "next/head";
import {site_data} from "../data/site_data";

const Meta = ({title, desc, keyword, image}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png"/>
        <meta name="description" content={desc}/>
        <meta name="keyword" content={keyword}/>

        <meta property="og:site_name" content={site_data.title}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={desc}/>
        <meta property="og:image" content={image}/>

        <meta name="twitter:card" content={desc}/>
        <meta name="twitter:site" content={site_data.twitter}/>
        <meta name="twitter:creator" content={site_data.twitter}/>

      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: site_data.title,
  desc: site_data.description,
  keyword: site_data.keyword,
  image: "/images/homepage/engineering-books.png"
};

export default Meta;
