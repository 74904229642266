import React from "react";

const Item = ({title, description}) => {
  return (<div className="flex space-x-4 mb-6">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="h-8 w-8 shrink-0 fill-accent"
    >
      <path fill="none" d="M0 0h24v24H0z"/>
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"/>
    </svg>
    <div>
      {title &&
        <span className="mb-3 pt-1 block font-display text-base font-semibold text-jacarta-900 dark:text-white">{title}</span>
      }
      {description &&
        <span className="dark:text-jacarta-60">{description}</span>
      }
    </div>
  </div>)
};

export default Item;
