const team_data = [
  {
    id: '1',
    image: '/images/users/miguelbemartin.jpg',
    title: 'Software Engineering Lead',
    name: 'Miguel Martín',
    twitter: 'https://twitter.com/miguelbemartin',
    linkedin: 'https://www.linkedin.com/in/miguelbemartin/',
  },
  {
    id: '2',
    image: '/images/users/josealobato.jpg',
    title: 'Software Developer',
    name: 'Jose A Lobato',
    twitter: 'https://twitter.com/JoseALobato',
    linkedin: 'https://www.linkedin.com/in/joseantoniolobato/',
  },
];

export {team_data};
